import React, { useState } from 'react';
import { Modal, Row, Col, notification, Select, } from 'antd';

const { Option } = Select;

function SalesOfficeModal(props) {
    const [selectedSalesOffice, setSelectedSalesOfice] = useState([]);  

    const handleOk = () => {
        if (selectedSalesOffice.length === 0)
            return notification.info({ message: 'Oficina de ventas', description: 'Selecciona una oficina de ventas.', });
        props.onOk(props.salesOffice.find(x => x.id === selectedSalesOffice));
    };

    return <Modal title="Oficina de ventas"
        open={props.visible}
        onOk={handleOk}
        okText="Tomar"
        cancelText="Cancelar"
        centered={true}
        closable={false}
        cancelButtonProps={{
            style: { display: 'none' }
        }}
    >

        <Row>
            <Col span={24}>
                <Select
                    style={{ width: '100%' }}
                    onChange={(value) => setSelectedSalesOfice(value)}
                    placeholder='Oficina de ventas...'
                    maxTagCount='responsive'>
                    {props.salesOffice.map(function (obj) {
                        return (
                            <Option key={obj.id} value={obj.id}>
                                {obj.name}
                            </Option>
                        );
                    })}
                </Select>
            </Col>
        </Row>
    </Modal>
}

export default SalesOfficeModal;