import { CheckCircleTwoTone, CloseCircleTwoTone, FundTwoTone, WarningTwoTone } from '@ant-design/icons';
import { BsClockHistory } from 'react-icons/bs';
import { Button, Checkbox, Col, Divider, Form, Input, Modal, Popconfirm, Row, Select, notification, Tooltip, Collapse } from 'antd';
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router';
import config from '../../config.json';

import useAuth from '../../hooks/useAuth';
import useSuspensions from './../../hooks/useSuspensions';
import auth from "../../services/authService";
import { getClients } from '../../services/clientService';
import { createContract, update } from '../../services/contractService';
import { getCountries } from '../../services/countryService';
import { getPostalCodes } from '../../services/postalCodeService';
import { getSalesOffices } from '../../services/salesOfficeService';
import { getServicePlans } from '../../services/servicePlansService';
import { useStatusHistogram } from '../../hooks/statusHistogram';

import ReactivateModal from './ReactivateModal';
import SuspensionModal from "./SuspensionModal";
import SuspensionsTable from './SuspensionsTable';
import Cancel from './cancel';
import Equipment from './equipment';
import StatusHistoryDrawer from './StatusHistoryDrawer';

const { TextArea } = Input;

// TODO: Should be in another part. Apears also in contract.jsx component
const configuration = {
    salesOrganizationId: '1',
    contractTypeId: 'S01'
};

function ContractForm({ contractId, fetchContract }) {
    const { user } = useAuth();
    const navigate = useNavigate();
    const { data: suspensions } = useSuspensions(contractId);
    
    const [contract, setContract] = useState(null);
    const [openSuspendModal, setOpenSuspendModal] = useState(false);
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [openReactivateModal, setOpenReactivateModal] = useState(false);
    const [form] = Form.useForm();
    const [cancelled, setCancelled] = useState(false);
    const [suspended, setSuspended] = useState(false);
    const [open, setOpen] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [loadingCatalogs, setLoadingCatalogs] = useState(false);
    const [loadingSettlements, setLoadingSettlements] = useState(false);
    const [clients, setClients] = useState([]);
    const [allClients, setAllClients] = useState([]);
    const [servicePlans, setServicePlans] = useState([]);
    const [salesOffices, setSalesOffices] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [neighborhoods, setNeighborhoods] = useState([]);
    const [allStatesWithCities, setAllCitiesWithStates] = useState([]);
    const [allPostalCodes, setAllPostalCodes] = useState([]);
    const [useClientAddress, setUseClientAddress] = useState(true);
    const [shouldLoadContract, setShouldLoadContract] = useState(false);
    const sideInfoSpan = shouldLoadContract ? 24 : 0;
    const [disable, setDisable] = useState(true);
    const [loadGuardar, setLoadGuardar] = useState(false);
    const [openDrawerHistogram, setOpenDrawerHistogram] = useState(false);

    const {
        isLoading: loadingHistorial,
        data: statusHistogram,
        refetch: refetchStatusHistogram
    } = useStatusHistogram(contractId);

    useEffect(() => {
        setDisable(false);
        form.resetFields();
        setCancelled(false);
        if (contractId) {
            gContract();
            return;
        }
        loadCatalogs();

    }, [contractId]);

    const reloadContract = () => {
        if (shouldLoadContract) {
            form.resetFields();
            gContract();
        }
    }

    const refreshContract = () => {
        form.resetFields();
        gContract();
    }

    const gContract = async () => {
        try {
            const contractResult = await fetchContract(contractId);
            loadCatalogs(contractResult);
        } catch (error) {
            if (error.response && error.response.status === 404)
                navigate(`/intelinet/servicios/contrato-not-found`);
        }
    }

    const loadCatalogs = async (contractResult) => {
        try {
            setLoadingCatalogs(true);
            setLoadingSettlements(true);
            const [
                clientsResult,
                servicePlansResult,
                salesOfficesResult,
                countriesResult,
            ] = await Promise.all([
                getClients(),
                getServicePlans(),
                getSalesOffices(),
                getCountries(),
            ]);
            const clients = clientsResult.data
                .map(client => ({
                    label: `${client.name} ${client.lastName} ${client.motherLastName}`,
                    value: client.id,
                }))
                .sort((a, b) => a.label.localeCompare(b.label));
            const plans = servicePlansResult.data;
            const servicePlans = plans.map(servicePlan => ({
                label: servicePlan.name,
                value: `${servicePlan.productId},${servicePlan.categoryId}`,
            }));
            const salesOffices = salesOfficesResult.data.map(salesOffice => ({
                label: salesOffice.name,
                value: salesOffice.id,
            }));
            const mxStates = countriesResult.data.find(
                country => country.id === config.country
            );
            const states = mxStates.states
                .map(state => ({
                    label: state.name,
                    value: state.id,
                }))
                .sort((a, b) => a.label.localeCompare(b.label));
            const allCitiesWithStates = mxStates.states.map(state => ({
                stateId: state.id,
                cities: state.municipalities,
            }));

            setClients(clients);
            setServicePlans(servicePlans);
            setSalesOffices(salesOffices);
            setStates(states);
            setAllCitiesWithStates(allCitiesWithStates);
            setAllClients(clientsResult.data);
            if (contractId) {
                setDisable(true);
                loadContract(contractResult, allCitiesWithStates);
                setShouldLoadContract(true);
            }
        } finally {
            setLoadingCatalogs(false);
            setLoadingSettlements(false);

        }
    };

    const loadNeighborhoods = async (stateId, cityId, postalCodeId) => {

        try {
            setLoadingSettlements(true);
            const postalCodesResult = await getPostalCodes(
                config.country,
                stateId,
                cityId
            );
            const { data: postalCodes } = postalCodesResult;
            const neighborhoods = postalCodes
                .map(postalCode => ({
                    label: postalCode.settlement,
                    value: postalCode.id,
                }))
                .sort(function (a, b) {
                    return a.label.localeCompare(b.label);
                });

            setNeighborhoods(neighborhoods);
            setAllPostalCodes(postalCodes);

            if (postalCodeId !== null) {
                const postalCode = postalCodes.find(
                    postalCode => postalCode.id === postalCodeId
                );
                form.setFieldValue('postalCode', postalCode?.code);
            }
        } finally {
            setLoadingSettlements(false);
        }
    };

    const handleCityChange = value => {
        form.setFieldValue('neighborhoodId', null);
        form.setFieldValue('postalCode', null);
        const stateId = form.getFieldValue('stateId');
        loadNeighborhoods(stateId, value);
    };

    const handleFilter = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const handleStateChange = value => {
        form.setFieldsValue({
            cityId: null,
            neighborhoodId: null,
        });
        setLoadingSettlements(true);
        updateCities(value);
    };

    const updateCities = stateId => {
        const state = allStatesWithCities.find(state => state.stateId === stateId);
        const cities = state.cities
            .map(city => ({
                label: city.name,
                value: city.id,
            }))
            .sort(function (a, b) {
                return a.label.localeCompare(b.label);
            });
        setCities(cities);
        setLoadingSettlements(false);
    };

    const handleNeighborhoodChange = value => {
        const postalCode = allPostalCodes.find(
            postalCode => postalCode.id === value
        );
        form.setFieldValue('postalCode', postalCode.code);
    };

    const loadContract = async (contractResult, allCitiesWithStates) => {
        try {
            const { contractStatusId } = contractResult;

            await loadNeighborhoods(
                contractResult.countryStateID,
                contractResult.countryStateMunicipalityID,
                contractResult.postalCodeID
            );
            const municip = allCitiesWithStates.filter(x => x.stateId === contractResult.countryStateID)[0].cities;
            const cities = municip.map(city => ({ label: city.name, value: city.id, })).sort(function (a, b) {
                return a.label.localeCompare(b.label);
            });
            setCities(cities);

            const contractIsCancelled =
                contractStatusId === config.statusContract.cancelado;
            const contractIsSuspended =
                contractStatusId === config.statusContract.suspendido;
            const contractIsOpen =
                [config.statusContract.solicitado, 
                 config.statusContract.instalado,
                 config.statusContract.instalacionAprobada,
                 config.statusContract.instalacionRechazada].some(s => s === contractStatusId);
            const contractIsCompleted =
                contractStatusId === config.statusContract.activo;

            setCancelled(contractIsCancelled);
            setSuspended(contractIsSuspended);
            setOpen(contractIsOpen);
            setCompleted(contractIsCompleted);
            setContract(contractResult);

            if (contractIsCancelled) {
                const cancelledStatus = statusHistogram.find(
                    history => history.contractStatusId === contractStatusId
                );
                form.setFieldValue('cancelReason', cancelledStatus?.comments);
            }

            if (contractIsSuspended) {
                /**
                 * TODO Se necesitan las fechas de inicio y fin de la suspensión porque en el objeto de respuesta no se encuentra nada
                 */
            }

            form.setFieldsValue({
                clientId: contractResult.clientId,
                salesOfficeId: contractResult.salesOfficeId,
                planId: `${contractResult.productId},${contractResult.categoryId}`,
                street: contractResult.street,
                externalNumber: contractResult.numberExternal,
                internalNumber: contractResult.numberInternal,
                stateId: contractResult.countryStateID,
                cityId: contractResult.countryStateMunicipalityID,
                neighborhoodId: contractResult.postalCodeID,
                references: contractResult.addressReference,
                location: contractResult.location,
            });
        } finally {
            // setLoadingContract(false);
        }
    };

    const onChangeClientAddress = e => {
        const checked = e.target.checked;
        setUseClientAddress(checked);
        const { clientId } = form.getFieldsValue();
        loadClientAddress(checked, clientId);
    };

    const loadClientAddress = async (checked, clientId) => {
        if (checked && clientId) {
            const client = allClients.find(client => client.id === clientId);
            const { address } = client;
            const existsAnAddress = address !== null;

            if (existsAnAddress) {
                const {
                    street,
                    numberExternal,
                    numberInternal,
                    countryStateID,
                    countryStateMunicipalityID,
                    postalCodeID,
                    reference,
                    location,
                } = address;

                await loadNeighborhoods(
                    countryStateID,
                    countryStateMunicipalityID,
                    postalCodeID
                );

                updateCities(countryStateID);

                form.setFieldsValue({
                    street,
                    externalNumber: numberExternal,
                    internalNumber: numberInternal,
                    stateId: countryStateID,
                    cityId: countryStateMunicipalityID,
                    neighborhoodId: postalCodeID,
                    references: reference,
                    location,
                });
            }

            if (!existsAnAddress) resetClientAddress();
        }

        if (!checked) resetClientAddress();
    };

    const resetClientAddress = () => {
        form.setFieldsValue({
            street: '',
            externalNumber: '',
            internalNumber: '',
            stateId: '',
            cityId: '',
            neighborhoodId: '',
            postalCode: '',
            references: '',
        });
        setNeighborhoods([]);
        setCities([]);
    };

    const handleClientChange = value => {
        loadClientAddress(useClientAddress, value);
    };

    const handleFinish = async values => {
        setLoadGuardar(true);
        const plansIds = values.planId.split(',');
        const productId = plansIds[0];
        const categoryId = plansIds[1];
        const salesOfficeId = values.salesOfficeId;

        /*
         * When contract is loaded is only for update the plan
         */
        if (shouldLoadContract) {
            try {
                await update(contractId, {
                    productId,
                    categoryId,
                    salesOrganizationId: configuration.salesOrganizationId,
                    salesOfficeId,
                    details: [{
                        positionID: 1,
                        address: {
                            id: contract.addressId,
                            street: values.street,
                            numberExternal: values.externalNumber,
                            numberInternal: values.internalNumber,
                            countryId: config.country,
                            countryStateID: values.stateId,
                            countryStateMunicipalityID: values.cityId,
                            postalCodeID: values.neighborhoodId,
                            reference: values.references,
                            location: values.location,
                        }
                    }]
                });

                notification.success({ message: 'Contrato', description: 'Se actualizó el contrato.' });
            } catch (error) {
                /*
                ! Settear errores en el form
                */
            } finally {
                setLoadGuardar(false);
            }
        }

        /**
         * * When a contract is not loaded it means that it is a new contract
         */
        if (!shouldLoadContract) {
            const contract = {
                clientId: values.clientId,
                salesOrganizationId: configuration.salesOrganizationId,
                categoryId,
                productId,
                salesOfficeId,
                street: values.street,
                numberExternal: values.externalNumber,
                numberInternal: values.internalNumber,
                countryId: config.country,
                countryStateID: values.stateId,
                countryStateMunicipalityID: values.cityId,
                postalCodeID: values.neighborhoodId,
                reference: values.references,
                colony: values.neighborhoodId,
                createdBy: user.userid,
                contractTypeId: configuration.contractTypeId,
                includeInstallation: true,
                location: values.location,
            };

            try {
                await createContract(contract);

                onOkNewContract();
                form.resetFields();
            } catch (error) {
                /*
                ! Settear errores en el form
                */
            } finally {
                setLoadGuardar(false);
            }
        }
    };

    const onOkSuspendContract = () => {
        setOpenSuspendModal(false);
        reloadContract();
    };

    const onOkCancelContract = () => {
        setOpenCancelModal(false);
        reloadContract();
    };

    const onOkReactivateContract = () => {
        setOpenReactivateModal(false);
        reloadContract();
    };

    const onOkNewContract = () => {
        notification.success({ message: 'Contrato', description: 'Se creó el nuevo contrato.' });
    };

    const onCancelContract = () => {
        setOpenCancelModal(true);
    };

    const showDrawerHistogram = () => {
        if (!statusHistogram)
            return notification.info({ message: 'Histograma', description: 'El contrato no cuenta con historial de estatus.' });
        refetchStatusHistogram();
        setOpenDrawerHistogram(true);
    };

    return <React.Fragment>
        <SuspensionModal
            contractId={contractId}
            open={openSuspendModal}
            onCancel={() => setOpenSuspendModal(false)}
            onOk={onOkSuspendContract}
            suspensions={suspensions} />
        <Modal
            title='Cancelar contrato'
            centered
            open={openCancelModal}
            onCancel={() => {
                setOpenCancelModal(false);
            }}
            width={480}
            footer={[
                <Button
                    key={'btnCancel'}
                    onClick={() => {
                        setOpenCancelModal(false);
                    }}
                    type='primary'>
                    Cerrar
                </Button>,
            ]}>
            <div>
                <Cancel
                    contractId={contractId}
                    onOkCancelContract={onOkCancelContract}
                />
            </div>
        </Modal>
        <ReactivateModal
            contractId={contractId}
            open={openReactivateModal}
            onCancel={() => setOpenReactivateModal(false)}
            onOk={onOkReactivateContract} />

        <StatusHistoryDrawer history={statusHistogram} open={openDrawerHistogram} close={() => setOpenDrawerHistogram(false)} />

        <Row>
            <Col span={24}>
                <Row justify="end">
                    <Col>
                        <Tooltip title="Historial de Estatus">
                            <Button
                                loading={loadingHistorial}
                                type='primary'
                                onClick={showDrawerHistogram}>
                                <BsClockHistory />
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
            </Col>
            <Col span={24}>
                <Form layout='vertical' form={form} onFinish={handleFinish}>
                    <Row gutter={5}>
                        <Col span={8}>
                            <Form.Item
                                name='clientId'
                                label='Cliente'
                                rules={[
                                    { required: true, message: '¡Selecciona un cliente!' },
                                ]}>
                                <Select
                                    showSearch={true}
                                    style={{ width: '100%' }}
                                    placeholder='Federico Suárez'
                                    loading={loadingCatalogs}
                                    options={clients}
                                    filterOption={handleFilter}
                                    onChange={handleClientChange}
                                    disabled={disable}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name='planId'
                                label='Plan'
                                rules={[
                                    { required: true, message: '¡Selecciona un plan!' },
                                ]}>
                                <Select
                                    showSearch={true}
                                    style={{ width: '100%' }}
                                    placeholder='Plan 5Mb'
                                    loading={loadingCatalogs}
                                    options={servicePlans}
                                    filterOption={handleFilter}
                                    disabled={contractId ? !completed : false}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name='salesOfficeId'
                                label='Oficina de pago'
                                rules={[
                                    {
                                        required: true,
                                        message: '¡Selecciona una oficina de pago!',
                                    },
                                ]}>
                                <Select
                                    showSearch={true}
                                    style={{ width: '100%' }}
                                    placeholder='Oficina 23'
                                    loading={loadingCatalogs}
                                    options={salesOffices}
                                    filterOption={handleFilter}
                                    disabled={disable}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider orientation='left'>Domicilio</Divider>
                    {!shouldLoadContract && (
                        <Row gutter={5}>
                            <Col span={8}>
                                <Form.Item name='clientAddress'>
                                    <Checkbox
                                        onChange={onChangeClientAddress}
                                        checked={useClientAddress}>
                                        Domicilio del cliente
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                    )}
                    <Row gutter={5}>
                        <Col span={8}>
                            <Form.Item
                                name='street'
                                label='Calle'
                                rules={[
                                    {
                                        required: true,
                                        message: '¡Inserta una calle!',
                                        maxLength: 128,
                                    },
                                ]}>
                                <Input placeholder='Independencia' disabled={!contractId ? false : !completed} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name='externalNumber'
                                label='Número exterior'
                                rules={[
                                    {
                                        required: true,
                                        message: '¡Inserta un número exterior!',
                                    },
                                ]}>
                                <Input placeholder='586' disabled={!contractId ? false : !completed} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name='internalNumber' label='Número interior'>
                                <Input placeholder='12' disabled={!contractId ? false : !completed} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={5}>
                        <Col span={8}>
                            <Form.Item
                                name='stateId'
                                label='Estado'
                                rules={[
                                    { required: true, message: '¡Selecciona un estado!' },
                                ]}>
                                <Select
                                    showSearch={true}
                                    style={{ width: '100%' }}
                                    placeholder='Jalisco'
                                    loading={loadingSettlements}
                                    options={states}
                                    onChange={handleStateChange}
                                    filterOption={handleFilter}
                                    disabled={!contractId ? false : !completed}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name='cityId'
                                label='Municipio'
                                rules={[
                                    { required: true, message: '¡Inserta un municipio!' },
                                ]}>
                                <Select
                                    showSearch={true}
                                    style={{ width: '100%' }}
                                    placeholder='San Juan de los Lagos'
                                    loading={loadingSettlements}
                                    options={cities}
                                    onChange={handleCityChange}
                                    filterOption={handleFilter}
                                    disabled={!contractId ? false : !completed}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name='neighborhoodId' label='Colonia'>
                                <Select
                                    showSearch={true}
                                    style={{ width: '100%' }}
                                    placeholder='Centro'
                                    loading={loadingSettlements}
                                    options={neighborhoods}
                                    onChange={handleNeighborhoodChange}
                                    filterOption={handleFilter}
                                    disabled={!contractId ? false : !completed}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={5}>
                        <Col span={8}>
                            <Form.Item name='postalCode' label='Código postal'>
                                <Input minLength={5} maxLength={5} disabled={true} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name='location' label='Localidad'>
                                <Input maxLength={128} disabled={!contractId ? false : !completed} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name='references' label='Referencias'>
                                <TextArea
                                    rows={3}
                                    autoSize={{ minRows: 3, maxRows: 3 }}
                                    placeholder='Casa verde con puerta negra'
                                    maxLength={128}
                                    disabled={!contractId ? false : !completed}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        {shouldLoadContract && completed && (
                            <Col>
                                <Form.Item>
                                    <Popconfirm
                                        title="¿Quieres actualizar el contrato?"
                                        onConfirm={() => form.submit()}
                                        okButtonProps={{
                                            loading: loadGuardar,
                                        }}
                                        cancelText="No"
                                        okText="Si"
                                    >
                                        <Button
                                            type='primary'
                                            htmlType='submit'
                                            icon={<FundTwoTone twoToneColor={'#7cb305'} />}
                                            loading={loadGuardar}>
                                            Actualizar
                                        </Button>
                                    </Popconfirm>
                                </Form.Item>
                            </Col>
                        )}
                        {!shouldLoadContract && (
                            <Col>
                                <Form.Item>
                                    <Button type='primary' htmlType='submit' loading={loadGuardar}>
                                        Guardar
                                    </Button>
                                </Form.Item>
                            </Col>
                        )}
                        {!suspended && !cancelled && completed && (
                            <Col >
                                <Button
                                    type='primary'
                                    block
                                    onClick={() => setOpenSuspendModal(true)}
                                    disabled={!auth.isInRoles('SERVICE_LAYOFF')}>
                                    <WarningTwoTone twoToneColor={'#facc15'} /> Suspender
                                </Button>
                            </Col>
                        )}
                        {!cancelled && completed && (
                            <Col >
                                <Button
                                    type='primary'
                                    block
                                    onClick={() => onCancelContract()}
                                    disabled={!auth.isInRoles('SERVICE_CANCEL')}>
                                    <CloseCircleTwoTone twoToneColor={'#dc2626'} /> Cancelar
                                </Button>
                            </Col>
                        )}
                    </Row>

                    {cancelled && (
                        <>
                            <Divider orientation='left'>Motivo de cancelación</Divider>
                            <Row style={{ margin: '0.5rem' }} justify='center'>
                                <Col span={24}>
                                    <Form.Item
                                        name='cancelReason'
                                        label='Motivo de cancelación'>
                                        <TextArea rows={3} disabled={true} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    )}

                    {suspended && (
                        <Col>
                            <Button
                                type='primary'
                                onClick={() => setOpenReactivateModal(true)}
                                block
                                style={{marginBottom: '24px'}}
                                disabled={!auth.isInRoles('SERVICE_REACTIVATE')}>
                                <CheckCircleTwoTone twoToneColor={'#16a34a'} /> ReActivar
                            </Button>
                        </Col>
                    )}
                </Form>
            </Col>
            <Col span={24}>
                {shouldLoadContract && open && (
                    <>
                        <Divider orientation='left'>
                            Parámetros de instalación
                        </Divider>
                        <Row>
                            <Col span={24}>
                                <Equipment
                                    contractId={contractId}
                                    contractStatus={contract.contractStatusId}
                                    clientId={contract.clientId}
                                    productId={contract.productId}
                                    salesOfficeId={contract.salesOfficeId}
                                    planCategoryId={contract.categoryId}
                                    setReloadContract={reloadContract}
                                    refreshContract={refreshContract}
                                />
                            </Col>
                        </Row>
                    </>
                )}
            </Col>
            {/* If the contract is in one of these three statuses, 
                it means that the contract was active at some point in time */}
            {(suspended || cancelled || completed) && (
                <Col span={sideInfoSpan}>
                    <Row gutter={20}>
                        <Col span={24}>
                            <Collapse style={{ borderRadius: '10px' }}>
                                <Collapse.Panel header='Historial de suspensiones' key='1'>
                                    <SuspensionsTable data={suspensions} contractId={contractId} />
                                </Collapse.Panel>
                            </Collapse>
                        </Col>
                    </Row>
                </Col>
            )}
        </Row>
    </React.Fragment>
}

export default ContractForm;