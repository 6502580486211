import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, InputNumber, notification, Popconfirm, Row, Skeleton, Spin, Table } from 'antd';
import SalesOfficeModal from '../../components/modalSalesOffice';
import { getSalesOfficesMy } from '../../services/salesOfficeService';
import cashReconciliationService from '../../services/cashReconciliationService';
import { FaBuilding, FaPencilAlt } from 'react-icons/fa';
import { currencyFormat } from '../../utils/currencyFormat';

import config from "../../config.json";

const createDataSource = () => {
    return config.money.denominations.map((item, index) => ({
        key: index,
        denomination: item,
        count: 0,
    }))
}

const CashCount = () => {
    const [moneyDataSource, setMoneyDataSource] = useState(createDataSource());
    const [isActive, setIsActive] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [loadingClosing, setLoadingClosing] = useState(false);
    const [isLoadingStatus, setIsLoadingStatus] = useState(false);
    const [modalSalesOfficeVisible, setModalSalesOfficeVisible] = useState(false);
    const [dataSalesOffice, setDataSalesOffice] = useState([]);
    const [salesOffice, setSalesOffice] = useState();
    const [btnEditOfficeVisible, setBtnEditOfficeVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 750);
 
    useEffect(() => {
        async function getSalesOffice() {
            const { data } = await getSalesOfficesMy();
            setDataSalesOffice(data);

            // If there is access to more than one office. The modal will be displayed
            if (data.length > 1) {
                setBtnEditOfficeVisible(true);
                setModalSalesOfficeVisible(true);
                return;
            }

            // If there is access to a single office. The modal won't be displayed
            if (data.length === 1) {
                setIsLoading(false);
                getCashRegisterStatus(data[0].id);
                setSalesOffice(data[0]);
                return;
            }

            // If there's no access to an office. The Alert will be displayed
            setIsLoading(false);
        }
        getSalesOffice();
    }, []);

    const getCashRegisterStatus = async (id) => {
        setIsLoadingStatus(true);

        try {
            const { data } = await cashReconciliationService.getCurrentCashRegister(id);
            // To check if the cash register is open
            setIsActive(data.active);
        } finally {
            setIsLoadingStatus(false);
        }
    }

    const handleModalOkSalesOffice = (office) => {
        setSalesOffice(office);
        getCashRegisterStatus(office.id);
        setIsLoading(false);
        setModalSalesOfficeVisible(false);
    }

    const handleCountChange = (key, value) => {
        const index = moneyDataSource.findIndex((item) => item.key === key);

        // findIndex returns -1 if it does not find the item
        if (index !== -1) {
            const newData = [...moneyDataSource];
            newData[index] = { ...newData[index], count: value + 0 };
            setMoneyDataSource(newData);
        }
    };

    // To allow writing only positive numbers
    const handleKeyDown = (e) => {
        if (e.key.length === 1 && /\D/.test(e.key)) {
            e.preventDefault();
        }
    };

    // To allow paste only positive numbers
    const handlePaste = (e) => {
        const pasteData = e.clipboardData.getData('text');
        if (!/^\d+$/.test(pasteData)) {
            e.preventDefault();
        }
    };

    const handleConfirm = async () => {
        try {
            setLoadingClosing(true);
            // noZero stores the non-zero values of the inputs
            const noZero = moneyDataSource.filter(money => money.count !== 0 || money.count);
            const mappedMoney = noZero.map(m => ({ // 'm' stands for 'money'
                positionId: m.key,
                cashValue: m.denomination,
                cashQuantity: m.count
            }))

            await cashReconciliationService.closeCashRegister(salesOffice, mappedMoney);
            notification.success({ message: 'Arqueo de caja', description: 'La caja ha sido cerrada satisfactoriamente.' });
            getCashRegisterStatus(salesOffice.id);
            handleClearTable();
        } catch (error) {
            if (error.response && error.response.status === 400)
                notification.warning({ message: 'Arqueo de caja', description: error.response.data });
        } finally {
            setLoadingClosing(false);
        }
    }

    const handleClearTable = () => {
        // Create a new array with count initialized to 0
        const clearedData = moneyDataSource.map(item => ({ ...item, count: 0 }));
        setMoneyDataSource(clearedData);
    };

    const columns = [
        {
            title: 'Denominación',
            dataIndex: 'denomination',
            key: 'denomination',
            align: 'right',
            width: '10%',
            render: (value) => `${ currencyFormat(value) }`
        },
        {
            title: 'Cantidad',
            dataIndex: 'count',
            key: 'count',
            align: 'center',
            width: '30%',
            render: (_, record) => (
                <InputNumber
                    min={0}
                    maxLength={15} // To avoid the exponential notation '1.000+e'
                    className='input-number'
                    onKeyDown={handleKeyDown}
                    onPaste={handlePaste}
                    value={record.count}
                    style={{ width: '100%' }}
                    onChange={(value) => handleCountChange(record.key, value)}
                />
            ),
        },
        {
            title: 'Importe',
            key: 'total',
            align: 'right',
            width: '60%',
            render: (_, record) => `${ currencyFormat(record.denomination * record.count) }`,
        },
    ];

    // If the screen is equal or smaller than 750px means Mobile View is being used
    const mobileMediaQuery = window.matchMedia('(max-width: 750px)');
    mobileMediaQuery.addEventListener('change', (event) => { 
        setIsMobile(event.matches);
    });

    // Splits the data in two for the two tables in the Desktop View
    const midIndex   = Math.ceil(moneyDataSource.length / 2);
    const firstHalf  = moneyDataSource.slice(0, midIndex);
    const secondHalf = moneyDataSource.slice(midIndex)

    const totalCash = moneyDataSource.reduce((sum, item) => sum + item.denomination * item.count, 0);

    return (
        <>
            <SalesOfficeModal
                visible={modalSalesOfficeVisible}
                salesOffice={dataSalesOffice}
                onOk={handleModalOkSalesOffice}
            />

            <div className='main-container'>
                <Row>
                    <Col span={24}>
                        <h1 className='title'>Arqueo de caja</h1>
                        <hr className='hr-title' />
                    </Col>
                </Row>

                <Spin spinning={isLoadingStatus}>
                    
                    <Row className='detail-container'>
                        <Skeleton active loading={isLoading}>

                            {/* No permission to the sales offices */}
                            {!salesOffice && (
                                <Alert
                                    message='Sin permiso'
                                    description='Usted no tiene permisos para las oficinas de ventas.'
                                    type='info'
                                    style={{ width: '100%' }}
                                    showIcon
                                />
                            )}

                            {/* There's permission to the sales offices */}
                            {salesOffice && (
                                <Col span={24}>
                                    <h4 className="div-right color-p">
                                        <FaBuilding /> {salesOffice.name}
                                        <Button type="primary" style={{ marginLeft: '5px' }} className={'collapse' + (btnEditOfficeVisible ? 'in' : '')}
                                            shape="circle" icon={<FaPencilAlt />} onClick={() => setModalSalesOfficeVisible(true)} />
                                    </h4>
                                </Col>
                            )}

                            {/* The cash register is closed */}
                            {salesOffice && !isActive && (
                                <Alert
                                    message='Caja cerrada'
                                    description='No existe caja abierta para la oficina de ventas seleccionada.'
                                    type='info'
                                    style={{ width: '100%' }}
                                    showIcon
                                />
                            )}

                            {/* There's permission to the sales offices */}
                            {salesOffice && isActive && (
                                <Col span={24}>
                                    { isMobile ? (
                                        <Table
                                            dataSource={moneyDataSource}
                                            columns={columns}
                                            pagination={false}
                                            bordered
                                            />
                                    ) : (
                                        <Row gutter={[16, 16]}>
                                            <Col span={12}>
                                                <Table columns={columns} dataSource={firstHalf} pagination={false} bordered />
                                            </Col>
                                            <Col span={12}>
                                                <Table columns={columns} dataSource={secondHalf} pagination={false} bordered />
                                            </Col>
                                        </Row>
                                    )}

                                    <strong className='div-right' style={{ marginTop: '18px', marginRight: '1rem' }}>
                                        Total {currencyFormat(totalCash)}
                                    </strong>
                                </Col>
                            )}

                            {/* There's permission to the sales offices */}
                            {salesOffice && isActive && (
                                <Col span={24} style={{ marginTop: '18px' }}>
                                    <Row gutter={16} justify='end'>
                                        <Button
                                            type='ghost'
                                            htmlType='button'
                                            size='middle'
                                            style={{ marginRight: '10px' }}
                                            onClick={handleClearTable}
                                        >
                                            Limpiar
                                        </Button>

                                        <Popconfirm
                                            title={(<span>¿Confirmar cierre de caja en <strong>{salesOffice.name}</strong> con <strong>{ currencyFormat(totalCash) || '$0.00'}</strong>?</span>)}
                                            okText='Continuar'
                                            onConfirm={handleConfirm}
                                            okButtonProps={{ loading: loadingClosing }}
                                            placement='left'
                                            cancelText='Cancelar'
                                        >
                                            <Button
                                                type='primary'
                                                htmlType='button'
                                                size='middle'
                                            >
                                                Cerrar
                                            </Button>
                                        </Popconfirm>
                                    </Row>
                                </Col>
                            )}
                        </Skeleton>
                    </Row>
                
                </Spin>
            </div>
        </>
    )
}

export default CashCount;