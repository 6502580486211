import moment from 'moment';
import config from '../config.json';
/** ISO date format without time zone 'YYYY-MM-DDTHH:mm:ss'. */
export const iso = 'YYYY-MM-DDTHH:mm:ss';

/**
 * Formats an instance of Date to ISO string without time zone 'YYYY-MM-DDTHH:mm:ss'.
 * @param {Date} date - The date instance to format.
 * @return {string} The string date in ISO format.
 */
export function toISO(date) {
    if(!(date instanceof Date))
        throw new Error('Parameter \'date\' is not an instance of Date');
    
    return moment(date).format(iso);
}

export function toISODates(items) {
    return items.map(d => {
        if(d.high)
            d.high = toISO(d.high);
    
        d.low = toISO(d.low);
        return d;
    });
}

// DD/MM/YYYY h:mm a
export function dateTimeFormatter(date) {
    return moment(date).format(config.dateTimeFormat);
}