import React, { useState } from 'react';
import { notification, Button, Form } from 'antd';
import UploadItem from './characteristics/items/UploadItem';
import StringItem from './characteristics/items/StringItem';
import NumberItem from './characteristics/items/NumberItem';
import SliderItem from './characteristics/items/SliderItem';
import { addResults } from "./../../services/characteristicsService";
import config from '../../config.json';

const CharacteristicsForm = (props) => {
    const [loading, setLoading] = useState(false);
    const [dataValues, setDataValues] = useState(props.datos);
    const [form] = Form.useForm();

    // This will remove properties and will be stored in formProps variable
    const { objectId, submitBtnWrapperCol, items, datos, headerTextMessage, ...formProps } = props;

    const handleFinish = async () => {
        form.validateFields().then(async () => {
            setLoading(true);
            const results = dataValues.map((d) => ({
                characteristicId: d.characteristicId,
                classCategoryId: d.classCategoryId,
                classId: d.classId,
                classTypeId: d.classTypeId,
                stringValue: d.stringValue
            }));

            try {
                await addResults(results, objectId);
                notification.success({ message: headerTextMessage, description: 'Se actualizó la información.' });
            } catch (error) {
                if (error.response && error.response.status === 400)
                    notification.warning({ message: headerTextMessage, description: error.response.data });
            }finally {
                setLoading(false);
            }
        }).catch(() => { });
    }

    const datason = (data, accion) => {
        const tempData = [...dataValues];
        const item = tempData.find(item => item.characteristicId === data.id);
        item.stringValue = accion ? null : data.valor;

        setDataValues(tempData);
    }

    return <Form form={form} {...formProps} onFinish={handleFinish} >
        {props.items.map(i => {
            const info =  datos.find(d => d.characteristicId === i.characteristicId);

            const props = {
                key: i.characteristicId,
                datason,
                name: i.name,
                label: i.name,
                required: info.mandatory,
                characteristicId: i.characteristicId,
                text: info.stringValue
            };

            switch(i.characteristicType.id){
                case config.characteristicType.integer:
                    return <NumberItem { ...props } allowDecimals={false} />
                case config.characteristicType.image:{
                    const dat = info.stringValue ? [{name: info.name, url: `${window.location.origin}${info.stringValue}`}] : null;
                    return <UploadItem { ...props } img={dat} />;
                }
                case config.characteristicType.string:
                    return <StringItem { ...props } />;
                case config.characteristicType.decimal:{
                    props.rango = info.characteristicValues;
                    return i.characteristicValues && i.characteristicValues[0]?.valueCode === 3
                        ? <SliderItem width='100%' disabled={formProps.disabled} { ...props } />
                        : <NumberItem { ...props } />;
                }
                default: throw new Error(`Characteristic of type '${i.characteristicType.name}' not supported.`);
            }
        })}
        {!formProps.disabled && <Form.Item wrapperCol={submitBtnWrapperCol}>
            <Button type="primary" htmlType="submit" loading={loading}>Guardar</Button>
        </Form.Item>}
    </Form>
}

export default CharacteristicsForm;