import React, { useEffect, useMemo, useState } from 'react';
import { Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';
import { Button, notification, Spin } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { getMonthlyAmount } from '../../services/dashboardService';
import { numberFormat } from '../../utils/numberFormat';
import { dashboardFormatter } from '../../utils/dashboardFormatter';
import { getRandomColor } from '../../utils/randomColor';

const transformData = (data) => {
    const groupedData = {};

    data.forEach(({ periodYear, periodMonth, salesOfficeId, debitAmount }) => {
        const key = `${periodMonth}-${periodYear}`;

        // To initialize the key
        if (!groupedData[key]) {
            groupedData[key] = { key };
        }

        const officeKey = `Oficina ${salesOfficeId}`;
        groupedData[key][officeKey] = (groupedData[key][officeKey] || 0) + debitAmount;
        
    });
    
    return Object.values(groupedData);
}

// Obtain office names to dynamically create Chart Bars
const getOfficeNames = (data) => {
    return [
         ...new Set( // Set to store unique values
             data.flatMap(Object.keys) // Get the objects keys
                 .filter(key => key !== 'key') // Remove 'key'
         )
    ];
 }

// To extend the size of the chart container dynamically
const dynamicWidth = (offices, data) => {
    const widthPerOffice = offices?.length * 50;
    return data?.length * widthPerOffice;
}

// If the screen is equal or smaller than 700px means Mobile View is being used
const mobileMediaQuery = window.matchMedia('(max-width: 700px)');

// To generate the Bars and Legend colors
const dynamicColors = (offices) => {
    const colors = [];

    for (let index = 0; index < offices?.length; index++) {
        const color = getRandomColor(50, 100);
        colors.push(color);
    }

    return colors;
}

const AmountMonthChart = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [chartData, setChartData] = useState();
    const [officeNames, setOfficeNames] = useState();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 700);

    const getChartData = async ()  =>{
        try {
            setIsLoading(true);
            const {data} = await getMonthlyAmount();
            const newData = transformData(data);
            setChartData(newData); 
            setOfficeNames( getOfficeNames(newData) );
        } catch (error) {
			if (error.response && error.response.status === 400) {
				notification.warning({ message: 'Adeudos mensuales/Oficina', description: error.response.data });
			}
        }
        finally {
            setIsLoading(false);
        }
    }

    const officeColors = useMemo(() => dynamicColors(officeNames), [chartData]);

    mobileMediaQuery.addEventListener('change', (event) => { 
        setIsMobile(event.matches);
    });

    useEffect(() => {
        getChartData();
    }, []);

    return (
        <Spin spinning={isLoading} >
            <h2>
                Adeudos mensuales/Oficina
                <Button type="primary" shape="circle" icon={<ReloadOutlined />} 
                        onClick={() => getChartData()} style={{ marginLeft: 8 }}
                        loading={isLoading}
                />
            </h2>


            {/* To prevent shape-shifting */}
            { isLoading &&
                <div style={{ width: '100%', height: '400px' }}></div>
            }

            { !isLoading && 
                <div className='chart-overflow'>
                    <BarChart data={chartData} width={dynamicWidth(officeNames, chartData)} height={400}>
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis dataKey='key' />
                        <YAxis tickFormatter={(value) => dashboardFormatter(value, 1, '$')} />
                        <Tooltip formatter={(value) => numberFormat(value)} />
                        {
                            officeNames?.map((element, index) => 
                                <Bar key={element} dataKey={element} fill={officeColors[index]} />
                            )
                        }
                    </BarChart>
                </div>
            }

            {/* Custom Legend */}
            {!isLoading && (
                isMobile ?
                    (<ul className='chart-custom-legend-mobile'>
                        {officeNames?.map((element, index) => (
                            <li key={element} style={{ color: officeColors[index], display: 'flex', alignItems: 'center' }}>
                                <div className='chart-bar-legend-square' style={{ backgroundColor: officeColors[index] }}></div> {element}
                            </li>
                        ))}
                    </ul>)
                    :
                    (<ul className='chart-custom-legend-desktop' >
                        {officeNames?.map((element, index) => (
                            <li key={element} style={{ color: officeColors[index], display: 'flex', alignItems: 'center' }}>
                                <div className='chart-bar-legend-square' style={{ backgroundColor: officeColors[index] }}></div> {element}
                            </li>
                        ))}
                    </ul>)
            )}
        </Spin>
    )
}

export default AmountMonthChart;