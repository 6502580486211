import React, { useState } from 'react';
import { rejectInstallation } from '../../services/contractService';
import { Form, Modal, Input, notification } from 'antd';



export const RejectInstallationModal = ({ contractId, open, onCancel, onOkRejectInstallation }) => {
    const { TextArea } = Input;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const handleOk = () => {
        form.validateFields()
            .then(async values => {
                try {
                    setLoading(true);
                    await rejectInstallation(contractId, values.rejectInstallationReason);
                    notification.success({ message: 'Contrato', description: 'El contrato ha sido rechazado satisfactoriamente.' });
                    onOkRejectInstallation();
                    form.resetFields();
                } catch (error) {
                    if (error.response && error.response.status === 400)
                        notification.warning({ message: 'Rechazar', description: error.response.data });
                } finally {
                    setLoading(false);
                }
            }).catch(() => {});     
    }

    return (
        <Modal
            title="Rechazar instalación"
            open={open}
            onOk={handleOk}
            okText='Guardar'
            onCancel={onCancel}
            cancelText='Cerrar'
            confirmLoading={loading}
            centered>
            <Form layout='vertical' form={form}>
                <Form.Item
                    name='rejectInstallationReason'
                    label='Ingresar motivo del rechazo'
                    rules={[
                        {
                            required: true,
                            message: 'Ingresa el motivo del rechazo',
                        },
                    ]}>
                    <TextArea
                        rows={4}
                        placeholder='El cableado está instalado de manera desordenada.'
                        maxLength={255}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}
