export const dashboardFormatter = (number, decimals = 0, prefix = '') => {
    if (number >= 1000000000) { // Billions
        return `${prefix}${ (number/1000000000).toFixed(decimals) }B`;
    } else if (number >= 1000000) { // Millions
        return `${prefix}${ (number/1000000).toFixed(decimals) }M`;
    } else if (number >= 1000) { // Thousands
        return `${prefix}${ (number/1000).toFixed(decimals) }K`;
    } else { // Less than a thousand
        return number.toString();
    }
}