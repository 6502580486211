import React from 'react';
import { Form, Input  } from 'antd';
import { subslate } from "subslate";

import config from '../../../../config.json';

const NumberInput = ({ value, onChange, datason, characteristicId, disabled, placeHolder, width, prefix, precision, allowDecimals, allowNegatives }) => {

    const handleChange = ({ target }) => {
		const newValue = target.value.trim();
		let tmp = newValue;
        
        // If allowNegatives is false and first char is -
        if(!allowNegatives && newValue[0] === "-") return;

        // If first char is -
        if(newValue.length === 1 && newValue[0] === "-") {
            onChange(newValue);
            return;
        }

		// Ends with .
		if (newValue.slice(-1) === ".") tmp = tmp + "0";

		const newNumber = Number(tmp);

		if (Number.isNaN(newNumber)) return;

        // If not allow decimal and has decimal point
        if(!allowDecimals && newValue !== "" && !/^[+-]?\d+$/.test(newValue)) return;

        // precision refers to the number of allowed decimal places
        if ( precision && allowDecimals && !new RegExp(`^-?\\d*\\.?\\d{0,${precision}}$`).test(newValue) ) return;

        onChange?.(newValue);
        // To avoid getting an error when using <NumberItem /> outside <CharacteristicsForm />
        if (datason) {
            datason({ id: characteristicId, valor: newValue }, false);
        }
	};

    return <Input type="text" className="input-number" style={{ width }} prefix={prefix} value={value} disabled={disabled} placeholder={placeHolder} onChange={handleChange} />
}

const NumberItem = ({ characteristicId, name, label, text, required, disabled, rango, datason, width = '200px', prefix, precision, allowDecimals = true, allowNegatives = true }) => {
    const range = rango && rango[0];
  
    const validator = (_, value) => {
        // Required
		if(required && (value == null || value === ""))
            return Promise.reject(new Error(subslate(config.antd.form.validateMessages.required, { label })));

        if(!value || !range) return Promise.resolve();

        // Range
        const number = Number(value);

        // Only valueTo
        if(!range.valueTo){
            return number > range.valueFrom
                ? Promise.reject(new Error(subslate(config.forms.validationMessages.max, { max: range.valueFrom })))
                : Promise.resolve();
        }

        return number < range.valueFrom || number > range.valueTo
            ? Promise.reject(new Error(subslate(config.forms.validationMessages.range, { from: range.valueFrom, to: range.valueTo })))
            : Promise.resolve();
	};

    const placeHolder = React.useMemo(() => {
        if(!range) return null;

        if(!range.valueTo) return `Max. ${range.valueFrom}`;

        return `${range.valueFrom} a ${range.valueTo}`;
    }, [range]);

    return <Form.Item name={name} label={label} rules={[{ validator, required }]} initialValue={text}>
        <NumberInput datason={datason} characteristicId={characteristicId} disabled={disabled} placeHolder={placeHolder} width={width} prefix={prefix} precision={precision} allowDecimals={allowDecimals} allowNegatives={allowNegatives} />
    </Form.Item>
}

export default NumberItem;