// Check if the value does not exceed the valid rgb range (0 - 255)
const validateRange = (value) => {
    return value < 0 || value > 255; 
}

export const getRandomColor = ( minRange = 0, maxRange = 255 ) => {

    if (
        validateRange(maxRange) || 
        validateRange(minRange) ||
        minRange > maxRange // minRange shouldn't be greater than maxRange
    ) {
        throw new Error(`The range must be between 0 and 255`);
    } else if (minRange + maxRange > 255) {
        throw new Error(`The sum between minRange and maxRange must not exceed 255`);
    }
    

    // Generate the rgb values
    const r = Math.floor(Math.random() * maxRange) + minRange;
    const g = Math.floor(Math.random() * maxRange) + minRange;
    const b = Math.floor(Math.random() * maxRange) + minRange;

    return `rgb(${r}, ${g}, ${b})`;
}
