import http from './httpService';

const endpoint = 'CashReconciliation';

export function closeCashRegister(salesOffice, money) {
        return http.post(`${endpoint}/Close`, {
                salesOfficeId: salesOffice.id,
                pointOfSaleId: salesOffice.id,
                details: money
        });
}

export function getCashReport(salesOfficesIds, startDate, endDate) {
        return http.post(`${endpoint}/Report`, {
                salesOfficesIds,
                startDate,
                endDate
        });
}

export function getCurrentCashRegister(salesOfficeId) {
        return http.get(`${endpoint}/Current?salesOfficeId=${salesOfficeId}&pointOfSaleId=${salesOfficeId}`);
}

export function openCashRegister(salesOffice, startAmount) {
        return http.post(`${endpoint}/Open`, {
                salesOfficeId: salesOffice.id,
                pointOfSaleId: salesOffice.id,
                startAmount
        });
}

export function withdrawCash(salesOfficeId, amount, retirementReason) {
        return http.post(`${endpoint}/WithDraw`, {
                salesOfficeId,
                pointOfSaleId: salesOfficeId,
                amount,
                retirementReason
        });
}

export default {
        closeCashRegister,
        getCashReport,
        getCurrentCashRegister,
        openCashRegister,
        withdrawCash,
}