import React from 'react';

import { AiFillHome } from 'react-icons/ai';
import { FaBook, FaWifi, FaTags, FaCubes, FaCashRegister, FaMoneyBillWave, FaCalculator, FaMoneyCheck } from 'react-icons/fa';
import { MdPayments, MdOutlineInventory2 } from 'react-icons/md';
import { TbReplace, TbReportMoney, TbReportAnalytics } from 'react-icons/tb';
import { RiArticleLine, RiBookletFill, RiDashboardFill } from 'react-icons/ri';
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

export const SidebarData = [
	{
		title: 'Home',
		path: '/intelinet',
		icon: <AiFillHome />,
	},
	{
		title: 'Planes',
		path: '/intelinet/planes',
		icon: <RiBookletFill />,
		roles: 'PLAN_READ'
	},
	{
		title: 'Clientes',
		path: '/intelinet/clientes',
		icon: <FaBook />,
		roles: 'CLIENT_READ'
	},
	{
		title: 'Servicios',
		path: '/intelinet/servicios',
		icon: <FaWifi />,
		roles: 'SERVICE_READ'
	},
	{
		title: 'Caja',
		icon: <FaCashRegister />,
		roles: ['CASH_RECON_OPEN', 'CASH_RECON_WITHDRAW', 'CASH_RECON_CLOSE', 'CASH_RECON_REPORT'],
		iconOpened: <IoIosArrowUp />,
		iconClosed: <IoIosArrowDown />,
		subNav: [
			{
				title: 'Apertura',
				path: '/intelinet/caja/apertura',
				icon: <FaMoneyBillWave />,
				roles: 'CASH_RECON_OPEN',
			},
			{
				title: 'Retiro',
				path: '/intelinet/caja/retiro',
				icon: <FaMoneyCheck />,
				roles: 'CASH_RECON_WITHDRAW',
			},
			{
				title: 'Arqueo',
				path: '/intelinet/caja/arqueo',
				icon: <FaCalculator />,
				roles: 'CASH_RECON_CLOSE',
			},
			{
				title: 'Reporte',
				path: '/intelinet/caja/reporte',
				icon: <TbReportMoney />,
				roles: 'CASH_RECON_REPORT',
			},
		],
	},
	{
		title: 'Dashboard',
		path: '/intelinet/dashboard',
		icon: <RiDashboardFill />,
		roles: ['DASHBOARD_AMOUNT_MONTH', 'DASHBOARD_CLIENTS_MONTH']
	},
	{
		title: 'Pagos',
		path: '/intelinet/pagos',
		icon: <MdPayments />,
		roles: 'PAYMENT_READ'
	},
	{
		title: 'Reporte adeudos',
		path: '/intelinet/reporte-adeudos',
		icon: <TbReportAnalytics />,
	},
	{
		title: 'Reporte Movim.',
		path: '/intelinet/reporte-movimientos',
		icon: <TbReportMoney />,
	},
	{
		title: 'Reporte Corte',
		path: '/intelinet/reporte-corte',
		icon: <RiArticleLine />,
	},
	{
		title: 'Movimientos',
		path: '/intelinet/movimientos',
		icon: <TbReplace />,
	},
	{
		title: 'Inventario',
		path: '/intelinet/inventario',
		icon: <MdOutlineInventory2 />,
	},
	{
		title: 'Categorias',
		path: '/intelinet/categorias',
		icon: <FaTags />,
		roles: 'CATEGORY_READ'
	},
	{
		title: 'Productos',
		path: '/intelinet/productos',
		icon: <FaCubes />,
		roles: 'PRODUCT_READ'
	},

];
