import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Button, Col, DatePicker, Form, notification, Row, Select, Skeleton, Table, Tag, Tooltip } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/es_ES';
import { CSVLink } from 'react-csv';
import { IoMdDocument } from 'react-icons/io';
import { FaFileExcel } from 'react-icons/fa';
import { getSalesOfficesMy } from '../../services/salesOfficeService';

import moment from 'moment';
import config from '../../config.json';
import { dateTimeFormatter, toISO } from '../../utils/dateFormat';
import { currencyFormat } from '../../utils/currencyFormat';
import cashReconciliationService from '../../services/cashReconciliationService';

const { RangePicker } = DatePicker;

const CashReport = () => {
    const [form] = Form.useForm();
    const [dataSalesOffice, setDataSalesOffice] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [dataTable, setDataTable] = useState([]);
    const [isLoadingTable, setIsLoadingTable] = useState(false);
    const [btnExcelVisible, setBtnExcelVisible] = useState(false);

    useEffect(() => {
        async function salesOffice() {
            setIsLoading(true);
            try {
                const { data } = await getSalesOfficesMy();
                setDataSalesOffice(data);
            } finally {
                setIsLoading(false);
            }
        }
        salesOffice();
    }, []);

    const columns = [
        {
            title: '',
            dataIndex: 'dicrepancy',
            width: 20,
            fixed: 'left',
            filters: [
                {
                    text: 'Coinciden',
                    value: true
                },
                {
                    text: 'No coinciden',
                    value: false
                }
            ],
            onFilter: (value, record) => {
                const isMatch = amountsMatch(record);
                return value ? isMatch : !isMatch;
            },  
            render: (_, record) => 
                amountsMatch(record)
                    ? ''
                    : <Tooltip showArrow={false} title={'No coincide el Monto Esperado con los Movimientos.'}>
                        <WarningOutlined style={{ color: '#fbb72e'}} />
                      </Tooltip>
            
        },
        {
            title: '#Documento',
            dataIndex: 'id',
            width: 100,
            fixed: 'left'
        },
        {
            title: 'Oficina de ventas',
            dataIndex: 'salesOfficeName',
            width: 100,
            fixed: 'left'
        },
        {
            title: 'Fecha de apertura',
            dataIndex: 'date',
            width: 125,
            render: (value) => value && dateTimeFormatter(value)
        },
        {
            title: 'Fecha de cierre',
            dataIndex: 'finishedDate',
            width: 125,
            render: (value) => value && dateTimeFormatter(value)
        },
        {
            title: 'Estatus',
            dataIndex: 'isClosed',
            align: 'center',
            width: 50,
            render: (value) => value 
                                    ? <Tag color='#545750'>CERRADA</Tag> 
                                    : <Tag color='#1e5600'>ABIERTA</Tag>
        },
        {
            title: 'Usuario',
            dataIndex: 'userName',
            width: 125,
        },
        {
            title: 'Monto de apertura',
            dataIndex: 'startAmount',
            align: 'right',
            render: (value) => currencyFormat(value),
        },
        {
            title: 'Monto de cierre',
            dataIndex: 'endAmount',
            align: 'right',
            render: (_, record) => 
                <span style={{ color: amountsMatch(record) ? '' : '#bf3035'}}>
                    {currencyFormat(record.endAmount)}
                </span>,
        },
        {
            title: 'Movimientos',
            dataIndex: 'moveAmount',
            align: 'right',
            render: (value) => currencyFormat(value),
        },
        {
            title: 'Monto esperado',
            dataIndex: 'calculatedAmount',
            align: 'right',
            render: (_, record) => 
                <span style={{ color: amountsMatch(record) ? '' : '#bf3035'}}>
                    {currencyFormat( calculateAmount(record) )}
                </span>,
        },
        {
            title: 'Diferencia',
            dataIndex: 'difference',
            align: 'right',
            render: (_, record) => 
                <span style={{ color: amountsMatch(record) ? '' : '#bf3035'}}>
                    {currencyFormat( record.endAmount - calculateAmount(record) )}
                </span>,
        },
    ];

    const csvHeaders = columns.map( col => ({ label: col.title, key: col.dataIndex }));

    const disabledDate = current => current.isAfter(moment(), 'days');

    const calculateAmount = ({ startAmount, moveAmount }) => {
        return startAmount + moveAmount;
    }

    const amountsMatch = (element) => {
        // To avoid marking open cash registers as discrepancies
        if (!element.isClosed) {
            return true; 
        }
        return (element.endAmount === calculateAmount(element));
    }

    const getCSVData = () => {
        return dataTable.map( row => ({
            dicrepancy: amountsMatch(row) ? '' : 'X',
            id: row.id,
            salesOfficeName: row.salesOfficeName,
            date: row.date && dateTimeFormatter(row.date),
            finishedDate: row.finishedDate && dateTimeFormatter(row.finishedDate),
            isClosed: row.isClosed ? 'CERRADA' : 'ABIERTA',
            userName: row.userName,
            startAmount: currencyFormat(row.startAmount),
            endAmount: currencyFormat(row.endAmount),
            moveAmount: currencyFormat(row.moveAmount),
            calculatedAmount: currencyFormat( calculateAmount(row) ),
            difference: currencyFormat( row.endAmount - calculateAmount(row) )
        }));
    }

    const handleGenerate = () => {
        form.validateFields()
            .then(async values => {
                try {
                    setIsLoadingTable(true);
                    // Formats to ISO without time zone
                    const startDate = toISO(values.dateRange[0].startOf('day').toDate());
                    const endDate = toISO(values.dateRange[1].startOf('day').toDate());
                    setDataTable([]); // Reset DataTable
                    const { data } = await cashReconciliationService.getCashReport(values.salesOfficesSelector, startDate, endDate);
                    setDataTable(data);
                    setBtnExcelVisible( data.length !== 0 );
                } catch (error) {
                    if (error.response && error.response.status === 400){
                        notification.warning({ message: 'Reporte de caja', description: error.response.data });
                        setBtnExcelVisible(false);
                    }
                } finally {
                    setIsLoadingTable(false);
                }
            }).catch(() => { });
    }

    // Flag to detect if in the table there's a difference between Closing Amount and Calculated Amount.
    const anyDiscrepancy = useMemo(() => {
        return dataTable.some(element => !(element.endAmount === calculateAmount(element)));
    }, [dataTable]);

    return (
        <div className='main-container'>
            <Row>
                <Col span={24}>
                    <h1 className="title">Reporte de caja</h1>
                    <hr className="hr-title" />
                </Col>
            </Row>

            <div className='detail-container'>
                <Skeleton active loading={isLoading}>

                    {/* No permission to the sales offices */}
                    { dataSalesOffice && dataSalesOffice.length === 0 && (
                        <Alert
                            message='Sin permiso'
                            description='Usted no tiene permisos para las oficinas de ventas.'
                            type='info'
                            style={{ width: '100%' }}
                            showIcon
                        />
                    )}

                    {/* There's permission to the sales offices */}
                    { dataSalesOffice && dataSalesOffice.length > 0 && (
                        <Form layout='vertical' form={form}>
                            <Row align='bottom'>

                                <Col span={8}>
                                    <Form.Item
                                        name='salesOfficesSelector'
                                        label='Oficinas de ventas'
                                        rules={[
                                            {
                                                required: true,
                                                message: '¡Selecciona por lo menos una oficina!',
                                            },
                                        ]}
                                    >
                                        <Select
                                            allowClear
                                            className={'collapse in'}
                                            mode='multiple'
                                            loading={isLoading}
                                            disabled={isLoading}
                                            style={{ width: '100%' }}
                                            placeholder='Oficina de ventas'
                                            maxTagCount='responsive'>
                                            {dataSalesOffice.map(function (obj) {
                                                return (
                                                    <Select.Option key={obj.id} value={obj.id}>
                                                        {obj.name}
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col offset={1} span={5}>
                                    <Form.Item
                                        name='dateRange'
                                        label='Fecha de apertura'
                                        rules={[
                                            {
                                                required: true,
                                                message: '¡Selecciona una fecha de apertura!',
                                            },
                                        ]}
                                    >
                                        <RangePicker locale={locale} disabledDate={disabledDate} format={config.dateFormat}/>
                                    </Form.Item>
                                </Col>

                                <Col offset={6} span={4}>
                                    <Form.Item>
                                        <Button
                                            block
                                            type='primary'
                                            loading={isLoadingTable}
                                            icon={<IoMdDocument style={{ marginRight: '8px' }} />}
                                            onClick={handleGenerate}
                                        >
                                            Generar
                                        </Button>
                                    </Form.Item>
                                </Col>

                                <Col span={4} className={'collapse' + (btnExcelVisible ? 'in' : '')}>
                                    <CSVLink 
                                        className='ant-btn ant-btn-primary' 
                                        data={getCSVData()}
                                        headers={csvHeaders}
                                        filename='Reporte-De-Caja.csv'
                                    >
                                        <FaFileExcel style={{ marginRight: '8px'}}/>
                                        Descargar reporte
                                    </CSVLink>
                                </Col>

                            </Row>
                        </Form>
                    )}

                    {/* There's difference between Closing Amount and Calculated Amount */}
                    { dataSalesOffice && dataSalesOffice.length > 0 && anyDiscrepancy && (
                        <Alert
                            banner
                            className='mrg-2'
                            description={(<span>Se detectaron discrepancias entre el <strong>Monto de Cierre</strong> y el <strong>Monto Esperado</strong> en algunas fechas. En la última columna, puede consultar la <strong>Diferencia</strong> entre ambos montos. Estos valores aparecerán <strong>resaltados en rojo</strong> para su revisión.</span>)}
                            type='warning'
                            style={{ width: '100%' }}
                        />
                    )}

                    {/* There's permission to the sales offices */}
                    { dataSalesOffice && dataSalesOffice.length > 0 && (
                        <Row>
                            <Col span={24} className='mrg-2'>
                                <Table
                                    rowKey={(record) => `${record.id}-${record.salesOfficeName}`}
                                    dataSource={dataTable}
                                    columns={columns}
                                    scroll={{ x: 'auto' }}
                                    loading={isLoadingTable}
                                    pagination={{ hideOnSinglePage: true }}
                                />
                            </Col>
                        </Row>
                    )}

                </Skeleton>
            </div>
        </div>
    )
}

export default CashReport;