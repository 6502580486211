import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Form, notification, Popconfirm, Row, Skeleton, Space, Spin } from 'antd';
import { FaBuilding, FaPencilAlt } from 'react-icons/fa';
import { getSalesOfficesMy } from '../../services/salesOfficeService';
import cashReconciliationService from '../../services/cashReconciliationService';

import SalesOfficeModal from '../../components/modalSalesOffice';
import NumberItem from '../../components/common/characteristics/items/NumberItem';
import { currencyFormat } from '../../utils/currencyFormat';

const OpenCashRegister = () => {
  const [form] = Form.useForm();
  const [modalSalesOfficeVisible, setModalSalesOfficeVisible] = useState(false);
  const [dataSalesOffice, setDataSalesOffice] = useState([]);
  const [salesOffice, setSalesOffice] = useState();
  const [btnEditOfficeVisible, setBtnEditOfficeVisible] = useState(false);
  const [disableButtons, setDisableButtons] = useState(false);
  const [openPopconfirm, setOpenPopconfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingOpening, setIsLoadingOpening] = useState(false);
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);
  const [isActive, setIsActive] = useState(false);

  // To check every change that is made to the NumberItem
  const amount = Form.useWatch('monto', form);

  const getCashRegisterStatus = async( id ) => {
    setIsLoadingStatus(true);

    try {
      const { data } = await cashReconciliationService.getCurrentCashRegister(id);
      // To check if the cash register is open
      setIsActive(data.active);

      // If the cash register is open, the amount obtained from the API 
      // is displayed in NumberInput, otherwise an empty string is displayed
      data.active
        ? form.setFieldValue('monto', data.startAmount)
        : form.setFieldValue('monto', '');
    } finally {
      setIsLoadingStatus(false);
    }
  }

  const handleModalOkSalesOffice = (office) => {
    setSalesOffice(office);
    getCashRegisterStatus(office.id);
    setIsLoading(false);
    setDisableButtons(false);
    setModalSalesOfficeVisible(false);
  }

  useEffect(() => {
    async function getSalesOffice() {
      setDisableButtons(true);
      const { data } = await getSalesOfficesMy();
      setDataSalesOffice(data);
      
      // If there is access to more than one office. The modal will be displayed
      if (data.length > 1) {
        setBtnEditOfficeVisible(true);
        setModalSalesOfficeVisible(true);
        return;
      }
      
      // If there is access to a single office. The modal won't be displayed
      if (data.length === 1) {
        setIsLoading(false);
        getCashRegisterStatus(data[0].id);
        setSalesOffice(data[0]);
        return;
      }

      // If there's no access to an office. The Alert will be displayed
      setIsLoading(false);
    }
    getSalesOffice();
  }, []);

  useEffect(() => {
    setOpenPopconfirm( !(amount && amount >= 0) );
  }, [amount]);
  
  const handleConfirm = () => {
    form.validateFields()
      .then(async values => {
        try {
          setIsLoadingOpening(true);
          await cashReconciliationService.openCashRegister(salesOffice, values.monto);
          notification.success({ message: 'Apertura de caja', description: 'La caja ha sido abierta satisfactoriamente.' });
          setIsActive(true);
        } catch (error) {
          if (error.response && error.response.status === 400)
            notification.warning({ message: 'Apertura de caja', description: error.response.data });
        } finally {
          setIsLoadingOpening(false);
        }
      }).catch(() => { });
  }

  return (
    <>
      <SalesOfficeModal 
        visible={modalSalesOfficeVisible}
        salesOffice={dataSalesOffice} 
        onOk={handleModalOkSalesOffice} 
      />

      <div className='main-container'>
        <Row>
          <Col span={24}>
            <h1 className="title">Apertura de caja</h1>
            <hr className="hr-title" />
          </Col>
        </Row>

        <Spin spinning={isLoadingStatus}>

          <Row className='detail-container'>
            <Skeleton active loading={isLoading}>
            
              {/* No permission to the sales offices */}
              { !salesOffice && (
                <Alert
                  message='Sin permiso'
                  description='Usted no tiene permisos para las oficinas de ventas.'
                  type='info'
                  style={{ width: '100%' }}
                  showIcon
                />
              )}

              {/* There's permission to the sales offices */}
              { salesOffice && (
                <Col span={24}>
                  <h4 className="div-right color-p">
                    <FaBuilding /> {salesOffice.name}
                    <Button type="primary" style={{ marginLeft: '5px' }} className={'collapse' + (btnEditOfficeVisible ? 'in' : '')}
                      shape="circle" disabled={disableButtons} icon={<FaPencilAlt />} onClick={() => setModalSalesOfficeVisible(true)} />
                  </h4>
                </Col>
              )}

              {/* There's permission to the sales offices */}
              { salesOffice && (
                <Row>
                  <Form layout='vertical' form={form}>
                    <Space direction='horizontal' >
                      <NumberItem
                        name='monto'
                        label={( isActive ? 'Caja abierta' : 'Monto de apertura' )}
                        prefix={'$'}
                        width='50vw'
                        required={true}
                        precision={2}
                        text={''} // This is necessary for the validations to work correctly
                        disabled={isActive}
                        allowNegatives={false}
                      />

                      { !isActive && (
                      <Popconfirm
                        title={( <span>¿Confirmar apertura de caja en <strong>{salesOffice.name}</strong> con <strong>{ currencyFormat(amount) || '$0.00' }</strong>?</span> )}
                        okText='Continuar'
                        onConfirm={handleConfirm}
                        okButtonProps={{ loading: isLoadingOpening }}
                        cancelText='Cancelar'
                        disabled={openPopconfirm}
                      >
                        <Button
                          type='primary'
                          htmlType='button'
                          size='middle'
                          loading={isLoadingOpening}
                          disabled={disableButtons || openPopconfirm}
                        >
                          Abrir
                        </Button>
                      </Popconfirm>
                      )}
                      
                    </Space>
                  </Form>
                </Row>
              )}

            </Skeleton>
          </Row>
          
        </Spin>
      </div>
    </>
  )
}

export default OpenCashRegister;
