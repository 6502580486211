import React from 'react';
import { Col, Row } from 'antd';
import authService from '../../services/authService';
import AmountMonthChart from './AmountMonthChart';

export default function DashboardPage() {
    return (
        <>
            <div className='main-container'>
                <Row>
                    <Col span={24}>
                        <h1 className='title'>Dashboard</h1>
                        <hr className='hr-title' />
                    </Col>
                </Row>

                <div className='detail-container-without-overflow'>

                    { authService.isInRole('DASHBOARD_AMOUNT_MONTH') && (
                        <AmountMonthChart />
                    )}

                </div>
            </div>

        </>
    )
}
